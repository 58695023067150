import React from 'react';
// import AboutContainer from './About/AboutComp';
// import Banner from './Banner';
import HighLightsComp from './HighLights/HighLightsComp';

function About() {
  return <div>
  <div className='common-header-name content-text'> <span className='ml'></span>About</div> 
  <div className='about-route-wrapper content-text'>
  <HighLightsComp />
  
  {/* <AboutContainer />
  <Banner /> */}
  <div className="footer-bottom">
        <p>&copy; Muthukumaran Palace. All rights reserved.</p>
      </div>
  </div></div>
}

export default About;