import React from 'react';
import './AboutComp.css';
import welcomImage from "../Images/welcome1.png"
// import hotelImage1 from './hotelImage1.jpg'; // Import your hotel images
// import hotelImage2 from './hotelImage2.jpg';

const AboutContainer = () => {
    var image1 = "https://media.istockphoto.com/id/104731717/photo/luxury-resort.jpg?s=612x612&w=0&k=20&c=cODMSPbYyrn1FHake1xYz9M8r15iOfGz9Aosy9Db7mI=";
    var image2 = "https://img.freepik.com/premium-photo/minsk-belarus-august-2017-columns-guestroom-hall-reception-modern-luxury-hotel_97694-6572.jpg";
    return (
        <div className="about-container">
          <div className="image-container">
            <img src={welcomImage} alt="Lodge 1" className="lodge-image" />
          </div>
          <div className="content-container content-text">
            <h2 className='header-text'>Welcome</h2>
            <p className='mt'>At Muthukumaran Palace,</p>
            <p className='mt'>we believe that every journey begins with a warm welcome and ends with cherished memories.</p>
            <p className='mt'>Let us be the starting point of your adventure,where comfort, hospitality, and peace await.</p>
            <div className=' book-now-in-about'><a href="https://www.justdial.com/villupuram/Muthukumaran-Palace/9999P4146-4146-240120140121-C5U3_BZDET?auto=1&trkid=9982039671" target="_blank" className="book-now button">
            Book Now</a></div>
          </div>
          
        </div>
    );
}

export default AboutContainer;
