import React from 'react';
import './HighLightsComp.css';
// import Img from '../img2.png';
import MuthukumaranPalaceFrontView from "../Images/MuthukumaranPalaceFrontView.png";
import OurStory from "../Images/OurStory.png";
import OurGoal from "../Images/WhyUs 1.png";
import RiceMill from "../Images/RiceMill.png";
import OilMill from "../Images/oilMill.png";
import MiniHall from "../Images/miniHall.png";//build

const HighLightsComp = () => {
    return (
        <div className='about-main-container-wrapper'>
            <div className="highlights-comp-wrapper">
                <h3 className='highlights-comp-header'>A Few Highlights</h3>
                <div className='highlights-comp-content-wrapper'>
                    <div className='highlights-comp-content'>
                        <h2 className='highlights-comp-inner-hdr'>5 years +</h2>
                        <h4 className='highlights-comp-second-hdr'>experience of lodging</h4>
                        <div className='highlights-comp-main-content'>Indulge in five years of timeless comfort and luxury at our premier lodging destination.</div>            
                    </div>
                    <div className='highlights-comp-content'>
                        <h2 className='highlights-comp-inner-hdr'>600 meters</h2>
                        <h4 className='highlights-comp-second-hdr'>Distance from Angala Parameshwari Temple</h4>
                        <div className='highlights-comp-main-content'>Experience the convenience of our lodge just steps away from the Amman Temple.</div>            
                    </div>
                    <div className='highlights-comp-content'>
                        <h2 className='highlights-comp-inner-hdr'>20+ Rooms</h2>
                        <h4 className='highlights-comp-second-hdr'>leading the comfortable</h4>
                        <div className='highlights-comp-main-content'>Both ac and non ac rooms with attach bathrooms .</div>            
                    </div>
                </div>
            </div>
            <div>
                <div className="image-content-container-abt" style={{flexDirection:"row-reverse"}}>
                    <div className="image-container-abt">
                        <img src={OurStory} alt="Our-Story" className="content-image-abt" />
                    </div>
                    <div className="content-abt-right">
                        <h2>Our Story</h2>
                        <p className='mt'>With over 5 years of dedicated service, Muthukumaran Palace has become a favorite for visitors looking for a peaceful stay close to  <span className='text-highlight-blue'>Anagalaparameswari Kovil</span>, Melmalayanur .</p>
                        <p className='mt'>We're proud to be the first choice for many when it comes to lodging in Melmalayanur, offering comfort and convenience near the temple, making every visit memorable and comfortable for spiritual travelers.</p>
                    </div>
                </div>
                <div className="image-content-container-abt">
                    <div className="image-container-abt">
                        <img src={OurGoal} alt="Our-Goal" className="content-image-abt" />
                    </div>
                    <div className="content-abt-left">
                        <h2>Our Goal</h2>
                        <p className='mt'>At Muthukumaran Palace, our top priority is ensuring the comfort, hospitality, and cleanliness that our customers deserve.</p> 
                        <p className='mt'>We take pride in providing <span className='text-highlight-blue'>high-standard accommodations at affordable prices</span>, guaranteeing a peaceful and fulfilling stay for every guest. Balancing cost with quality, we strive to offer luxurious experiences without compromising on affordability.</p>
                        <p className='mt'>Our commitment means you enjoy premium service and accommodations, ensuring value for money in every aspect of your stay.</p>
                    </div>
                </div>
                {/* <div className="image-content-container-abt" style={{flexDirection:"row-reverse"}}>
                    <div className="image-container-abt">
                        <img src={MuthukumaranPalaceFrontView} alt="need to be changed" className="content-image-abt" />
                    </div>
                    <div className="content-abt-right">
                        <h2>Our Goal</h2>
                        <p>Welcome to our cozy lodge nestled in the heart of nature. We offer a peaceful retreat for those seeking relaxation and adventure.

                            Our lodge features comfortable accommodations, breathtaking views, and a variety of outdoor activities to enjoy.

                            Whether you're hiking through the forest, fishing in the nearby lake, or simply unwinding by the fireplace, we strive to make your stay unforgettable.</p>
                    </div>
                </div> */}
            </div>
            <div className='highlights-comp-other-facilities-hdr'><span role="img" aria-label="location">📍</span>Other Facilities</div>
            <div className='highlights-comp-other-facilities-wrapper'>
                <div className='highlights-comp-other-facilities-flex-wrapper'>
                    <div className=" highlights-comp-other-facilities-image">
                        <img src={OilMill} alt="Organic Oil" className="content-image-border" />
                        <h5>Organic Oil</h5>
                    </div>
                    <div className='highlights-comp-other-facilities-content'>
                        Pure and natural - explore the goodness of our organic oil.
                    </div>
                </div>
                <div className='highlights-comp-other-facilities-flex-wrapper'>
                    <div className=" highlights-comp-other-facilities-image">
                        <img src={RiceMill} alt="Rice Mill" className="content-image-border" />
                        <h5>Rice Mill</h5>
                    </div>
                    <div className='highlights-comp-other-facilities-content'>
                        Careful processing, premium rice - experience quality at our rice mill.
                    </div>
                </div>
                <div className='highlights-comp-other-facilities-flex-wrapper'>
                    <div className=" highlights-comp-other-facilities-image">
                        <img src={MiniHall} alt="=Mini Hall" className="content-image-border" />
                        <h5>Bala Bairavi Mini Hall</h5>
                    </div>
                    <div className='highlights-comp-other-facilities-content'>
                        Spacious and inviting - host ceremonies like ear piercing at our mini hall.
                    </div>
                </div>
                
            </div>
        </div>
    );

}

export default HighLightsComp;
