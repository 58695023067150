import React, { useEffect } from 'react';
import './MpFooter.css';
import CallIcon from "../Images/Call Icon.png";
import WhatsAppRoundIcon from "../WhatsAppRoundIcon.png";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';

const Footer = () => {
  useEffect(() => {
    const footerObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.animation = 'fadeIn 0.5s ease forwards';
          observer.unobserve(entry.target);
        }
      });
    });

    const footer = document.querySelector('.footer');
    if (footer) {
      footerObserver.observe(footer);
    }

    return () => {
      if (footer) {
        footerObserver.unobserve(footer);
      }
    };
  }, []);

  return (
    <footer className="footer content-text">
      <div>
      <div className="footer-content">
        {/* <div className="footer-section">
          Happy Place and Happy Visit
        </div> */}
        <div className="footer-section">
          <h3>Hotel Address</h3>
          <p className='mt'>Muthukumaran Palace</p>
          <p className='mt'>Behind Bharat Petrol Bunk, Melmalayanur</p>
          <p className='mt'>Viluppuram</p>
          <p className='mt'>Tamil Nadu 604204</p>

          {/* <h4 style={{marginTop:"20px", marginBottom:"5px"}}>Exclusivize Others</h4>
          <p>Organic Oil</p>
          <p>Rice mill</p> */}
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p className='mt'>Phone: 7092626459</p>
          <p className='mt'>Email: muthukumaranpalace@gmail.com</p>

          <h4 style={{marginTop:"20px", marginBottom:"5px"}}></h4>
          {/* <span role="img" aria-label="location">&#x1F4CD;</span><a href='https://www.google.com/maps/dir/Muthukumaran+Palace/Sri+Malayanur+Angala+Parameswari+Temple,+88VF%2BXJ4,+Melmalayanur,+Kodukankuppam,+Tamil+Nadu+604204/@12.3422242,79.3221069,16.5z/data=!4m13!4m12!1m5!1m1!1s0x3baccde931f8dbf5:0xcd0af867936858b0!2m2!1d79.3223405!2d12.3403061!1m5!1m1!1s0x3baccc5d2ad8fedf:0xdd149269eaaff3d!2m2!1d79.3240658!2d12.3448859?entry=ttu' target='_blank'>Location</a> */}
        </div>
        <div className="footer-section display-flex-column">
          <h3>Quick Links</h3>

          <Link to="/about" className='mt'>About Us</Link>
          <Link to="/facilities" className='mt'>Facilities</Link>
          <Link to="/contact" className='mt'>Enquiry</Link>
          <div style={{marginTop:"10px"}}><a href="https://www.justdial.com/villupuram/Muthukumaran-Palace/9999P4146-4146-240120140121-C5U3_BZDET?auto=1&trkid=9982039671" className="footer-booking just-dial-footer" target="_blank">Just Dial</a></div>

          {/* Add social icons or other content here */}
        </div>
      </div>
      <div className="footer-bottom">
        <p className='mt'>&copy; Muthukumaran Palace. All rights reserved.</p>
      </div>
      </div>
    </footer>
  );
}

export default Footer;
