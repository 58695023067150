import NearBy from './Images/LocationNear.png';
import LowCostIcon from './Images/LowCostIcon.png';
import BestService from './Images/Star.png';
import ShowCaseFacility from './facilities/showCaseFacility';

const GuestLove = () =>{
    return(
        <div class="guest-fav">
            <h1 className="header-text">Reasons to Stay</h1>
            <div className='highlights-comp-content-wrapper mt mt'>
            <div className='highlights-comp-content'>
            <ShowCaseFacility imageSrc={LowCostIcon} header="Best Service at Low Cost" parentPhotoClass="guest-icon">
                We Know Customers's Needs
            </ShowCaseFacility>
            </div>
            <div className='highlights-comp-content'>
                <ShowCaseFacility imageSrc={BestService} header="Quality Facilities" parentPhotoClass='guest-icon'>
                    Quality Uncompromised, Always.
                </ShowCaseFacility>
            </div>
            <div className='highlights-comp-content'>
                <ShowCaseFacility imageSrc={NearBy} header="Near Amman Temple" parentPhotoClass="guest-icon">
                    Built for Spiritual Journeys, Near the Temple.
                </ShowCaseFacility>
            </div>
            </div>
            <div class="fav-card"></div>
            <div class="fav-card"></div>
            <div class="fav-card"></div>
        </div>
    )
}

export default GuestLove;