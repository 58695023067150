import React, { useState, useEffect } from 'react';
import './image-detail.css';
import img1 from "./Images/usImage1.png";//Build-App
import img2 from "./Images/usImage3.png";
import img3 from "./Images/usImage2.png";
import img4 from "./Images/usImage4.png"

const ImageSwapper = (props) => {
  return (
    <div className='image-detail content-div '>
      <div className='photos-div'>
        {/*<div class="container-width">
          <div class="bar-width"></div>
        </div>
        <div class="container-height">
          <div class="bar-height"></div>
        </div>*/}
        <div className='display-flex'>
          <div className='display-flex-column display-justify-center ml'>
          <img src={img4} alt="Lodge 1" className="lodge-image blink1" />
          <img src={img2} alt="Lodge 1" className="lodge-image blink2" />
          </div>
          <div className='display-flex-column display-justify-center ml'>
          <div className='rightbar'></div>
          <img src={img3} alt="Lodge 1" className="lodge-image blink3" />
          </div>
        </div>
      </div>
      <div className='image-detail-content-div content-container scontent-text'>
        <h2 className='header-text why-heading'>
        Why US ?
        </h2>
        <div className='image-detail-lines content-text'>
        <p className='mt'>"Muthukumaran Palace stands as the  <span className='text-highlight-green'> top choice in Melmalayanur </span></p>
        <p className='mt'>We prioritize your comfort and value your money</p>
        <p className='mt'>we ensure that you experience both luxury and affordability,all while enjoying a peaceful retreat."</p>
        </div>
      </div>
    </div>
  );
};

export default ImageSwapper;