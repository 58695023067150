const HorizontalDiv = ({ imageSrc, header, children ,parentPhotoClass=""}) => {
    return (
      <div className="horizontal-div">
        <img src={imageSrc} alt="Image" className={`${parentPhotoClass}`} />
        <h2 className="facility-top-header">{header}</h2>
        <p className="facility-top-content">{children}</p>
      </div>
    );
  };

  export default HorizontalDiv;