import React from 'react';
import ImageSlider from './imageSlider';
import AboutContainer from './About/AboutComp';
import MpFooter from './Footer/MpFooter';// again check is builded
import ImageWithContent from './imageWithContent';
import NewCards from './Cards/NewCards';
import ImageDetail from './ImageDetail';
import img1 from "./M1.jpg";//Build-App
import img2 from "./M2.jpg";
import img3 from "./M3.jpg";
import whatsApp from "./WhatsApp Icon.png";
import callUs from "./Call Us Now Icon.png";
import MuthukumaranPalaceFrontView from "./Images/MuthukumaranPalaceFrontView.png";
import temple from "./Images/Melmalayanur amman.png";
import hotelFullView from "./Images/Hotel full view.png";
import GuestLove from './guestLove';
const imagesList1 = [img1, img2 ,img3];
const LandImg = [MuthukumaranPalaceFrontView, temple ,hotelFullView];
const mainContent = ["Near Angala Parameshwari Temple, our hotel awaits! Book now for a cozy stay just steps from this famous landmark"
                    ,"Experience comfort and convenience at our hotel! With modern amenities and a welcoming atmosphere, your stay will be memorable.",
                  "Near Angala Parameshwari Temple, our hotel awaits! Book now for a cozy stay just steps from this famous landmark"]
const roomsDescription = "Room details";
function Home() {
  return <div className="App">
  <div class="area" >
          <ul class="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
          </ul>
  </div >
    <div className="home smoke-bg btn-grad">
      <div className="hotel-info-main">
        <div className="hotel-name header-text">
          <div className='hotel-logo'>
           {/** <img class="logo-image" src={murugar} alt="Site Logo"></img>*/} 
          </div>
          <div>
            <h1>Muthukumaran Palace</h1>
          </div>
        </div>
        <div className="hotel-address">Behind Bharat Petrol Bunk, Melmalayanur, Viluppuram, Tamil Nadu - 604204</div>
        <div className='hotel-rooms'>
    </div>
        <a href="tel:7092626459">
        <img src={callUs} alt="Call Us Now Icon" className=" tel-info" /></a>
        <a href="https://wa.me/+917092626459?text=Hello,%20I'm%20looking%20for%20room%20in%20your%20hotel%20Muthu %20Kumaran%20palace." className="whatsApp">
        <img src={whatsApp} alt="WhatsApp Icon" className=" tel-info"/></a>
      <div className="center-content">
      <h6>"Experience our promise of comfort in budget-friendly rooms"</h6>
      </div>
      {/*<div className='style-bar'></div>*/}
      <div className="center-box wipe-enter display-justify-center">
      <img src={temple} alt="Lodge 1" className="lander-image" />
      <img src={MuthukumaranPalaceFrontView} alt="Lodge 1" className="lander-image" />
      <img src={hotelFullView} alt="Lodge 1" className="lander-image" />
      <div className='hotel-rooms-img'> <ImageSlider images={LandImg} /> </div>
         {/*<div className='hotel-main-img'> <ImageWithContent images={mainImages} content={mainContent} /> </div> */}
      </div>
      </div>
    </div>
    {/* <div className='hotel-rooms'>
      <div className='hotel-rooms-img'> <ImageSlider images={imagesList2} /> </div>
      <div className='hotel-rooms-description'> {roomsDescription}</div>
    </div> */}
  <AboutContainer />
    
      <div className='hotel-rooms-card'> 
      {/* <ImageSlider images={imagesList1} /> */}
      <NewCards />
      {/* <div className="content-text-white hotel-rooms-content" >Near Angala Parameshwari Temple, our hotel awaits! Book now for a cozy stay just steps from this famous landmark.</div> */}
      </div>
      <div className="guest-love">
        <GuestLove />
      </div>
    {/* className='content-div blue' */}
    <div>
      <ImageDetail images={imagesList1} />
    </div>
    <div>
      
    </div>
    <div>
      
    </div>
    <div>
      
    </div>
    <MpFooter />
  </div>;
}

export default Home;