import './App.css';
import React, { useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Facilities from './Facilities';
import WhatsAppSmall from "./WhatsAppRoundIcon.png";
import CallSmall from "./CallROundICon.png";
import CloseIcon from "./Images/CloseIcon.png";
import MenuIcon from "./Images/MenuIcon.png";
import HomeSmall from "./Images/Home.png";
import FacilitiesSmall from "./Images/Facilities.png";
import CallIcon from "./Images/Call Icon.png";
import AboutSmall from "./Images/About.png";
function ScrollToTop() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    console.log("asd" , isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
    <ScrollToTop />
        <header>
          <div className='ml ml display-flex fixed-text'>
            <div >Muthukumaran Palace</div>
            <div className='ml' ><img className='menu-icon-desk color-invert' src={CallIcon}/> 7092626459</div>
          </div>
          <nav>
           <div className='header-btn'>
            <ul>
              <li>
               <Link to="/"><img className='menu-icon-desk' src={HomeSmall}/> Home</Link>
              </li>
              <li>
                <Link to="/about"><img className='menu-icon-desk' src={AboutSmall}/> About</Link>
              </li>
              <li>
                <Link to="/facilities"><img className='menu-icon-desk' src={FacilitiesSmall}/> Facilities</Link>
              </li>
              <li>
                <Link to="/contact"><img className='menu-icon-desk color-invert' src={CallIcon}/> Contact</Link>
              </li>
            </ul>
          </div>
          <div className='header-btn-mob display-flex'>
          <div >
          </div>
          <div className="menu-toggle" onClick={toggleMenu}>
           <div className ={` ${isMenuOpen ? '' : 'close'}`}><img src={CloseIcon} alt="Close Icon" className="mobview-top-icon"/></div>
           <div className ={` ${isMenuOpen ? 'close' : ''}`}><img src={MenuIcon} alt="Menu Icon" className="mobview-top-icon"/></div>
          </div>
          <ul className={`menu ${isMenuOpen ? 'open' : ' close'}`}>
            <li  onClick={toggleMenu}><Link to="/"><div className='display-flex-column display-flex-center'><img className='menu-icon-desk' src={HomeSmall}/> <div>Home</div></div></Link></li>
            <li  onClick={toggleMenu} ><Link to="/about"><div className='display-flex-column display-flex-center'><img className='menu-icon-desk' src={AboutSmall}/>  <div>About</div></div></Link></li>
            <li  onClick={toggleMenu} ><Link to="/facilities"><div className='display-flex-column display-flex-center'><img className='menu-icon-desk' src={FacilitiesSmall}/>   <div>Facilities</div></div></Link></li>
            <li  onClick={toggleMenu}><Link to="/contact"><div className='display-flex-column display-flex-center'><img className='menu-icon-desk color-invert' src={CallIcon}/>   <div>Contact</div></div> </Link></li>
          </ul>
        </div>
          </nav>
        </header>
        <div className='mt60'></div>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
          <Route path="/facilities" element={<Facilities />} />
        </Routes>
        
    <div >
    <div class="container-btn">
      <a href="tel:7092626459" ><img src={CallSmall} alt="Call Us Icon" className="button-btm1"/></a>
      <a href="https://wa.me/+917092626459?text=Hello,%20I'm%20looking%20for%20room%20in%20your%20hotel%20Muthu %20Kumaran%20palace." >
      <img src={WhatsAppSmall} alt="WhatsApp Icon" className="button-btm"/>
      </a>
    </div>

  </div>
        </Router>
    
  );
}

export default App;
