import React from 'react';
import './Contact.css';
import parking from './icons/Parking.png';
import CallIcon from "./Images/Call Icon.png";
import Location from "./Images/LocationSmall.png";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';

function Contact() {
    return (
        <div className='content-text'>
        <div className='common-header-name content-text'> <span className='ml'></span>Contact</div>
        <div className='contact-location-wrapper'>
            <div className='contact-location-hotel-name-wrpper'>
                <div className='contact-location-name-container display-flex-column display-flex-center'>
                    <div className='contact-location-sub-header'>Smart Lodging & Happy Memories
                    <div className='contact-location-header'>Muthukumaran Palace & Bala Bairavi Mini Hall</div></div>
                </div>
                <div className="content-container content-text">
                    <h2>We Love To Help</h2>
                    <p className='mt'>We take pride in serving you with excellence.For inquiries or bookings, please don't hesitate to reach out to us.Our dedicated team is always ready to assist you.Contact us using the details below and let us make your experience unforgettable.</p>
                </div>
            </div>
          {/* <Link to="/">Back TO Home Page</Link> */}
            

            <div className='contact-location-contact-flex'>
                <div className='contact-location-contact'>
                    <div className='contact-location-contact-hdr'>
                        <div className='contact-location-contact-icon'>
                        {/* <img src={Location} alt="to - be - changed" className='conntact-location-img color-invert'/> */}
                        <div className='contact-location-contact-title'>Address</div></div>
                        <div className='content-location-cnt'>
                            <div>Behind Bharat Petrol Bunk, Melmalayanur, Viluppuram</div>
                            <div>Tamil Nadu 604204</div>
                        </div>
                    </div>
                </div>
                <div className='contact-location-contact'>
                    <div className='contact-location-contact-hdr'>
                        <div className='contact-location-contact-icon'>
                        {/* <img src={CallIcon} alt="to - be - changed" className='conntact-location-img' /> */}
                        <div className='contact-location-contact-title'>Contact Information</div></div>
                        <div className='content-location-cnt'>
                            <div>70926 26459</div>
                            <div> 94422 54317</div>
                        </div>
                    </div>
                </div>
                <div className='contact-location-contact'>
                    <div className='contact-location-contact-hdr'>
                        <div className='contact-location-contact-icon'>
                        {/* <p className='conntact-location-img'>&#9993; </p> */}
                        {/* <img src={parking} alt="to - be - changed" className='conntact-location-img' /> */}
                        <div className='contact-location-contact-title'>Mail Id</div>
                    </div>
                    <div className='content-location-cnt'>
                        <div>muthukumaranpalace@gmail.com</div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='contact-location-map-embed'> 
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d498897.33760208!2d79.32234!3d12.340306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baccde931f8dbf5%3A0xcd0af867936858b0!2sMuthukumaran%20Palace!5e0!3m2!1sen!2sin!4v1711471169906!5m2!1sen!2sin" width="400" height="300" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

            {/* <a href="tel:7092626459" className="button tel-info">Click to call us</a>
            <a href="https://wa.me/+917092626459?text=Hello,%20I'm%20looking%20for%20room%20in%20your%20hotel%20Muthu %20Kumaran%20palace." className="button tel-info">Chat On WhatsApp</a>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d498897.33760208!2d79.32234!3d12.340306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baccde931f8dbf5%3A0xcd0af867936858b0!2sMuthukumaran%20Palace!5e0!3m2!1sen!2sin!4v1711471169906!5m2!1sen!2sin" width="400" height="300" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
            <div className="footer-bottom">
            <p>&copy; Muthukumaran Palace. All rights reserved.</p>
          </div>
        </div>
        </div>
    );
}

export default Contact;
