import React from 'react';
import './FacilitiesComp.css';
import ACimg from './icons/Ac2.png';
import CCTV from './icons/CCTV.png';
import CCTV2 from './icons/cctv2.png';
import Bed from './icons/bed2.png';
import checkin from './icons/247.png';
import Hall2 from './icons/hall2.png';
import Fire from './icons/Fire.png';
import FirstAid from './icons/FirstAid.png';
import FreeWifi from './icons/FreeWifi.png';
import Parking from './icons/Parking2.png';
import Room from './icons/Room.png';
import ShowCaseFacility from './showCaseFacility';
import MuthukumaranPalaceFrontView from "../Images/MuthukumaranPalaceFrontView.png";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
// import Ac from './icons/Ac.png';

const FacilitiesComp = () => {
  return (
    <div>
    <div className='common-header-name content-text'> <span className='ml'></span> Facilities</div>
    <div className="facility-container content-text">
      <div className='facitity-top-hdr-wrapper'>
        <div className='facitity-top-hdr-inter-wrapper'>
          {/*<h2 className='facitity-top-hdr content-text'>Our Facilities</h2>*/}
          <p className='facitity-top-hdr-cont'>No Compromises on our facilities towards you, Our rule is serve you good facilities!</p>
        </div>
      </div>
    {/* <div className='hotel-header'>
      <h3 className="facility-heading">Hotel Facilities</h3>
      <div className='facility-header-caption'>Uncompromising excellence in every amenity. Your satisfaction is our standard.</div>
    </div> */}
      <div className="horizontal-container">
        <ShowCaseFacility imageSrc={FreeWifi} header="Free Wifi" parentPhotoClass='facility-top-image'>
        Wifi Free- stress free
        </ShowCaseFacility>
        <ShowCaseFacility imageSrc={Room} header="Mini Hall" parentPhotoClass='facility-top-image'>
          Big space, big possibilities: Make memories in our mini hall.
        </ShowCaseFacility>
        <ShowCaseFacility imageSrc={CCTV} header="Safety & Security" parentPhotoClass='facility-top-image'>
          Your safety, our priority: Securing peace of mind.
        </ShowCaseFacility>
      </div>
      <div className='facility-new-wrapper'>
        <div className='facility-new-left'>
          {/* <p className='facility-new-welcome'> Welcome to</p> */}
          <h3 className='facility-new-hdr-cont'>Premier Quality Facilities</h3>
          <div className='facility-new-cont'>
          <p className='mt'>Discover our range of amenities designed for your comfort and convenience</p> 
          <p className='mt'>From air-conditioned rooms to secure premises monitored by security cameras, rest assured in the comfort of cozy queen size beds</p>
          <p className='mt'>An ample parking space and 24/7 check-in availability, your stay is hassle-free</p>
          <p className='mt'>Choose from over 20 rooms tailored to suit your needs, ensuring a memorable experience at Muthukumaran Palace</p>
          </div>
          <Link to="/contact" className='button'>Enquiry</Link>
        </div>
        <div className='facility-new-right'>
            <div className='facility-new-why-us-content-wrapper'>
              <div className=' facility-new-why-us-content-120'>
                <div className='facility-new-icon'><img src={ACimg} alt="Image" className="facility-bottom-image" /></div>
                <div className='facility-new-icon-content'>Chill your mind and body</div>
              </div>
              <div className='facility-new-why-us-content'>
                <div className='facility-new-icon'><img src={CCTV2} alt="Image" className="facility-bottom-image" /></div>
                <div className='facility-new-icon-content'>Safety First, Others next</div>
              </div>
              <div className='facility-new-why-us-content'>
                <div className='facility-new-icon'><img src={Bed} alt="Image" className="facility-bottom-image" /></div>
                <div className='facility-new-icon-content'>Queen sized bed & Extra mattress available</div>
              </div>
              <div className='facility-new-why-us-content'>
                <div className='facility-new-icon'><img src={Parking} alt="Image" className="facility-bottom-image" /></div>
                <div className='facility-new-icon-content'>Park your Vehicle under Safety</div>
              </div>
              <div className=' facility-new-why-us-content-120'>
                <div className='facility-new-icon'><img src={checkin} alt="Image" className="facility-bottom-image" /></div>
                <div className='facility-new-icon-content'>easy checking and checkout</div>
              </div>
              <div className='facility-new-why-us-content'>
                <div className='facility-new-icon'><img src={Hall2} alt="Image" className="facility-bottom-image" /></div>
                <div className='facility-new-icon-content'>20+ Rooms at low cost</div>
              </div>
              
            </div>
        </div>
      </div>
      {/* <div className="facility-list">
        <div className="facility-column">
          <div className='facility-header'>Highlighted Amentities</div>
          <div className="facility-item"><img src={FreeWifi} alt="Image" className="facility-bottom-image" /> Free Wi-Fi</div>
          <div className="facility-item"><img src={ACimg} alt="Image" className="facility-bottom-image" /> Air Conditioning</div>
          <div className="facility-item"><img src={Parking} alt="Image" className="facility-bottom-image" /> Parking Facility</div>
        </div>
        <div className="facility-column">
          <div className='facility-header'>Safety and Security</div>
          <div className="facility-item"><img src={CCTV} alt="Image" className="facility-bottom-image" /> CCTV Camera</div>
          <div className="facility-item"><img src={FirstAid} alt="Image" className="facility-bottom-image" /> First Aid Kit</div>
          <div className="facility-item"><img src={Fire} alt="Image" className="facility-bottom-image" /> Fire Extinguisher</div>
        </div>
        <div className="facility-column">
          <div className='facility-header'>Basic Facilities</div>
          <div className="facility-item"><img src={Bed} alt="Image" className="facility-bottom-image" /> Queen Sized Bed & Extra Mattress Available</div>
          <div className="facility-item"><img src={checkin} alt="Image" className="facility-bottom-image" /> 24/7 Check-in & Check-out</div>
          <div className="facility-item"><img src={Room} alt="Image" className="facility-bottom-image" /> 250 sqft Room size & Attached Bathroom </div>
        </div>
      </div> */}
      
  <div className="footer-bottom">
  <p>&copy; Muthukumaran Palace. All rights reserved.</p>
</div>
    </div>
    </div>
  );
};


export default FacilitiesComp;
