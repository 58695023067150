import React, { useState, useEffect } from 'react';
function ImageSlider(props) {
// Create state variables for the index and image array
const [index, setIndex] = useState(0);
const images = props.images;// [img1 , img3 ,img1 ];
const content = props.content;// [img1 , img3 ,img1 ];
// const images = ["#0088FE", "#00C49F", "#FFBB28"];
// Use useEffect to increment the index and update the image every 5 seconds

useEffect(() => {
const intervalId = setInterval(() => {
setIndex(prevIndex => (prevIndex + 1) % images?.length);
}, 5000);

return () => clearInterval(intervalId);
}, []);

// Render the current image
return (
    <div className="slideshowContainer ">
    <div className="slideMainContent" >
    <div
      className="slideshowSlider"
      style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
    >
      {images.map((backgroundColor, index) => (
        <img src={images[index]} alt="Slider" className="slide" />
      ))}
        </div>
        <div className="slideshowDots">
  {images.map((_, idx) => (
    <img
      key={idx}
      className={`slideshowDotWithImg${index === idx ? " active" : ""}`}
      src={images[idx]}
      style={{
        width: '60px',
        margin: '1px',
        border: index === idx ? '#ffffff 2px solid' : 'none',
        opacity: index === idx ? '100%' : '50%'
      }}
      onClick={() => {
        console.log("c");
        setIndex(idx);
      }}
    />
  ))}
</div>
  </div>
  <div className='mainContent display-flex-center'>{content[index]}</div>
  </div>
// <img src={images[index]} alt="Slider" className="img-slider" />
);
}

export default ImageSlider;