import React from 'react';
import './NewCards.css'; // You can create a CSS file for styling
import ACimg from './icons/Ac.png';
import CCTV from './icons/CCTV.png';
import Bed from './icons/Bed.png';
import checkin from './icons/checkin.png';
import Fire from './icons/Fire.png';
import FirstAid from './icons/FirstAid.png';
import FreeWifi from './icons/FreeWifi.png';
import Parking from './icons/Parking.png';
import Room from './icons/Room.png';

const NewCards = () => {
  return (
    <div className="card-list">
      <h1 className="header-text service-headings">Everything You Need</h1>
      <div class="service-card">
        <Card number={"01"} isRight={false} header={"Mini Hall"} icon={Room} theme={"orange-t"}>
          <p>Gather loved ones - Seats 100+ for your special events!</p>
        </Card>
        <Card number={"02"} isRight={true} header={"Ac / Non - Ac Rooms"} icon={ACimg} theme={"red-t"}>
          <p>Choose your comfort - Relax in style, any weather.</p>
        </Card>
        <Card number={"03"} isRight={false} header={"Budget Rooms"} icon={FreeWifi} theme={"green-t"}>
          <p>Save your money, not your fun! Enjoy our budget-friendly rooms</p>
        </Card>
        <Card number={"04"} isRight={true} header={"24/7 Check in & Check out"} icon={checkin} theme={"purple-t"}>
          <p>Arrive anytime - We're here for you, day or night.</p>
        </Card>
        <Card number={"05"} isRight={false} header={"Parking"} icon={Parking} theme={"blue-t"}>
          <p>Forget the search - Easy Parking with Stress Free Stay.</p>
        </Card>
      </div>
    </div>
  );
};

const Card = ({ number, children, isRight, header, icon, theme }) => {

  return (
    <div className={' card-wrapper-outer ' + (theme + (isRight ? ' card-wrapper-right-theme ' : ' card-wrapper-left-theme '))}>
      {(isRight === false) ? (<div className='icon-internal-wrapper' >
        <div className='count-internal'> {number}</div>
       
      </div>) : <span></span>}
      
      <div className={' content-wrapper ' + (theme + (isRight ? ' content-wrapper-right-theme ' : ' content-wrapper-left-theme '))}>
        <div className='content-header'> {header}</div>
        <div className='content-text-small'>{children}</div>
      </div>
      {(isRight) ? (<div className='icon-internal-wrapper-right'>
        <div className='count-internal'> {number}</div>
      </div>) : <span></span>}
    </div>
  );
};

export default NewCards;
